<template>
    <section id="dashboard-fiordo-blending-planificacion-reasignacion-lotes">

         <b-row>
            <b-col sm="12">
                <ag-grid-table
                :configUrl="config1"
                :dataUrl="data1"
                :editUrl="edit1">
                </ag-grid-table>
            </b-col>
        </b-row>

        <b-row>
            <b-col sm="12">
                <ag-grid-table
                :configUrl="config2"
                :dataUrl="data2"
                :editUrl="edit2">
                </ag-grid-table>
            </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-card>
            <b-button
            variant="primary"
            class="float-right"
            @click="reemplazar"
            size="lg"
            >
            Reemplazar Lote
            </b-button>
            </b-card>
          </b-col>
        </b-row>

    </section>
</template>
<script>
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import useApiServices from '@/services/useApiServices.js';
import
{
  BRow,
  BCol,
} from "bootstrap-vue";
export default {  
    components: {
        AgGridTable,
        BRow,
        BCol
    },
    methods: { 
        reemplazar(){
            
        }
    },
    mounted()
    {
      
    },
    data()
    {
        return {

            config1: useApiServices.planificacionReasignacionLotesConfig01,
            data1: useApiServices.planificacionReasignacionLotesData01,
            edit1: useApiServices.planificacionReasignacionLotesEdit01,

            config2: useApiServices.planificacionReasignacionLotesConfig02,
            data2: useApiServices.planificacionReasignacionLotesData02,
            edit2: useApiServices.planificacionReasignacionLotesEdit02,

        };
    },
};
</script>
<style lang="scss" scoped>
span {
  font-size: 14px;
}
</style>